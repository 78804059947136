function getCurrency(value: string | number): number {
  if (value === undefined) return 0;
  if (value === null) return 0;
  if (value === '') return 0;

  if (typeof value === 'number') return value;

  return parseFloat(value);
}

function getDecimal(value: string | number, decimal: number): number {
  if (value === undefined) return 0;
  if (value === null) return 0;
  if (value === '') return 0;

  if (typeof value === 'number') return value;

  return parseFloat(parseFloat(value).toFixed(decimal));
}

function getFormatedDate(inValue: String): string {
  const localDate = inValue.split('-');
  const dia = localDate[2];
  const mes = localDate[1];
  const ano = localDate[0];
  return `${dia.toString().padStart(2, '0')}/${mes.toString().padStart(2, '0')}/${ano}`;
}

function getTodayFormated() {
  const tmpToday = new Date();
  const dia = tmpToday.getDate();
  const mes = tmpToday.getMonth() +1;
  const ano = tmpToday.getFullYear();

  return `${dia.toString().padStart(2, '0')}/${mes.toString().padStart(2, '0')}/${ano}`;
}

export { getCurrency, getDecimal, getFormatedDate, getTodayFormated };
