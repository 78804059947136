import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useToast } from '../../hooks/Toast';

import { Container } from './styles';

const Profile: React.FC = () => {
  const { addToast } = useToast();
  const history = useHistory();

  useEffect(() => {
    addToast({
      type: 'info',
      title: 'Página em construção',
      description: 'Estamos trabalhando para que esteja disponível em breve!',
    });

    // history.push('/');
    history.push('/companychange');
  }, [addToast, history]);

  return <Container />;
};

export default Profile;
