import React from 'react';
import { useHistory } from 'react-router-dom';
import Home from '@material-ui/icons/Home';
import { ROUTE_DASHBOARD, ROUTE_DASHBOARD_POWERBI } from '../../utils/constants';
import { ActiveSystemKindOptions } from '../../utils/appInfo';
import { ContainerDropdown } from './styles';
import { useAuth } from '../../hooks/Auth';

const MenuHome: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();

  const handleGoHome = (): void => {
    if (user && user.empresa && user.empresa.active_system_kind === ActiveSystemKindOptions.Option_FinancialAccounts) {
      history.push(ROUTE_DASHBOARD);
      return;
    }

    history.push(ROUTE_DASHBOARD_POWERBI);
  };

  return (
    <ContainerDropdown>
      <button type="button" onClick={handleGoHome}>
        <Home />
      </button>
    </ContainerDropdown>
  );
};

export default MenuHome;
