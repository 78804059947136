import React from 'react';
import { Route as ReactDOMRoute, RouteProps as ReactDOMRouteProps, Redirect } from 'react-router-dom';

import { useAuth } from '../hooks/Auth';
import { ActiveSystemKindOptions } from '../utils/appInfo';

interface IRouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean;
  isAdminPage?: boolean;
  component: React.ComponentType;
}

const Route: React.FC<IRouteProps> = ({ isPrivate = false, isAdminPage = false, component: Component, ...rest }) => {
  const { user, isMasterUser } = useAuth();

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        if (rest.path === '/home') {
          if (
            user &&
            user.empresa &&
            user.empresa.active_system_kind === ActiveSystemKindOptions.Option_FinancialAccounts
          ) {
            return <Redirect to="/dashboard" />;
          } else {
            return <Redirect to="/dashboardpowerbi" />;
          }
        }

        if (isPrivate && !!user && isAdminPage) {
          if (isMasterUser()) return <Component />;

          return (
            <Redirect
              to={{
                pathname: isPrivate ? '/' : '/home',
                state: { from: location },
              }}
            />
          );
        }

        if (
          rest.path === '/forgot-password' ||
          rest.path === '/user-request-acess' ||
          rest.path === '/signin' ||
          rest.path === '/signup' ||
          rest.path === '/reset-password'
        ) {
          return <Component />;
        }

        if (!isMasterUser() && user && !user.admin && user.redirectToCompanyRequest) {
          if (rest.path === '/profile') {
            return <Component />;
          }

          return <Redirect to="/user-request-acess" />;
        }

        if (!!user && !!user.empresa && user.empresa.viewer) {
          if (
            rest.path === '/' ||
            rest.path === '/home' ||
            rest.path === '/dashboardpowerbi' ||
            rest.path === '/companychange' ||
            rest.path === '/profile'
          ) {
            return <Component />;
          }
          return <Redirect to="/home" />;
        }

        return isPrivate === !!user ? (
          <Component />
        ) : (
          <Redirect
            to={{
              pathname: isPrivate ? '/' : '/home',
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

export default Route;
