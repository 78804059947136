import React, { useState, useEffect } from 'react';
import { Grid, Box, Paper } from '@material-ui/core';
import ArrowUpwardRoundedIcon from '@material-ui/icons/ArrowUpwardRounded';
import ArrowDownwardRoundedIcon from '@material-ui/icons/ArrowDownwardRounded';
import AttachMoneyRoundedIcon from '@material-ui/icons/AttachMoneyRounded';

import './style.css';

export enum CardBigNumbersKindOptions {
  optionReceivable = 1,
  optionPayable = 2,
  optionCashFlow = 3,
}

interface ICardBigNumbersProps {
  title: string;
  value1: number;
  label1: string;
  value2: number;
  label2: string;
  value3: number;
  label3: string;
  value4: number;
  label4: string;
  kindCard: CardBigNumbersKindOptions;
}

const CardBigNumbers: React.FC<ICardBigNumbersProps> = ({
  title,
  value1,
  label1,
  value2,
  label2,
  value3,
  label3,
  value4,
  label4,
  kindCard,
}) => {
  const [colorTodayValue, setColorTodayValue] = useState<string>('#007FFF');
  const [colorNext14DaysValue, setColorNext14DaysValue] = useState<string>('#007FFF');
  const [colorNext28DaysValue, setColorNext28DaysValue] = useState<string>('#007FFF');
  const [colorOverdueAmountsValue, setColorOverdueAmountsValue] = useState<string>('#007FFF');
  const [colorTextoverdueAmounts, setColorTextoverdueAmounts] = useState<string>('default');

  useEffect(() => {
    if (kindCard === CardBigNumbersKindOptions.optionReceivable) {
      setColorOverdueAmountsValue('red');
      setColorTextoverdueAmounts('red');
    } else if (kindCard === CardBigNumbersKindOptions.optionPayable) {
      setColorTodayValue('red');
      setColorNext14DaysValue('red');
      setColorNext28DaysValue('red');
      setColorOverdueAmountsValue('red');
      setColorTextoverdueAmounts('red');
    } else {
      if (value1 < 0) {
        setColorTodayValue('red');
      }

      if (value2 < 0) {
        setColorNext14DaysValue('red');
      }

      if (value3 < 0) {
        setColorNext28DaysValue('red');
      }

      if (value4 < 0) {
        setColorOverdueAmountsValue('red');
        setColorTextoverdueAmounts('red');
      }
    }
  }, [kindCard, value1, label1, value2, label2, value3, label3, value4, label4]);

  return (
    <Paper
      variant="outlined"
      style={{ padding: '10px', height: '160px', background: '#EDF3FB' }}
      className="astCardmargin"
    >
      <Grid container>
        <Grid item xs={1} sm={1}>
          {kindCard === CardBigNumbersKindOptions.optionReceivable && (
            <ArrowUpwardRoundedIcon
              style={{
                background: '#0BDA30',
                color: '#FFF',
                border: '1px solid #0BDA30',
                borderRadius: '50%',
                height: '32px',
                width: '32px',
                padding: '6px',
              }}
            />
          )}
          {kindCard === CardBigNumbersKindOptions.optionPayable && (
            <ArrowDownwardRoundedIcon
              style={{
                background: 'red',
                color: '#FFF',
                border: '1px solid red',
                borderRadius: '50%',
                height: '32px',
                width: '32px',
                padding: '6px',
              }}
            />
          )}
          {kindCard === CardBigNumbersKindOptions.optionCashFlow && (
            <AttachMoneyRoundedIcon
              style={{
                background: '#58855C',
                color: '#FFF',
                border: '1px solid #58855C',
                borderRadius: '50%',
                height: '32px',
                width: '32px',
                padding: '6px',
              }}
            />
          )}
        </Grid>
        <Grid item xs={5} sm={5}>
          <div className="astLeftContainerCardBigNumbers">
            <div>
              <span style={{ fontWeight: 'bold', color: '#007FFF' }}>{title}</span>
            </div>
            <div>
              <Box
                color={colorTodayValue}
                fontSize={{
                  xs: 24,
                  sm: 36,
                  md: 32,
                }}
              >
                {new Intl.NumberFormat('pt-BR', {
                  minimumFractionDigits: 2,
                }).format(Math.abs(value1))}
              </Box>
            </div>
            <div>
              <span>{label1}</span>
            </div>
          </div>
        </Grid>
        <Grid item xs={6} sm={6}>
          <div className="astContainerCardBigNumbers">
            <span style={{ color: colorNext14DaysValue }}>
              {new Intl.NumberFormat('pt-BR', {
                minimumFractionDigits: 2,
              }).format(Math.abs(value2))}
            </span>
            <span style={{ fontSize: 12 }}>{label2}</span>
          </div>
          <br />
          <div className="astContainerCardBigNumbers">
            <span style={{ color: colorNext28DaysValue }}>
              {new Intl.NumberFormat('pt-BR', {
                minimumFractionDigits: 2,
              }).format(Math.abs(value3))}
            </span>
            <span style={{ fontSize: 12 }}>{label3}</span>
          </div>
          <br />
          <div className="astContainerCardBigNumbers">
            <span style={{ color: colorOverdueAmountsValue }}>
              {new Intl.NumberFormat('pt-BR', {
                minimumFractionDigits: 2,
              }).format(Math.abs(value4))}
            </span>
            <span style={{ color: colorTextoverdueAmounts, fontSize: 12 }}>{label4}</span>
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default CardBigNumbers;
