import React, { useState, useEffect, useCallback } from 'react';
import IFramePowerBI from '../../components/IFramePowerBI';
import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';

import { Container, Content } from './styles';
import Header from '../../components/Header';
import { ActiveSystemKindOptions } from '../../utils/appInfo';

const DashboardPowerBI: React.FC = () => {
  const { user } = useAuth();
  const { addToast } = useToast();
  const [urlPowerBI, setUrlPowerBI] = useState<string>('');

  const licencaInvalida = useCallback((): boolean => {
    if (user && user.license.id === '') {
      addToast({
        type: 'error',
        title: 'Licença inválida',
        description: 'Usuário não está vinculado a nenhuma licença',
      });

      return true;
    }

    return false;
  }, [user, addToast]);

  const isUserAdmin = useCallback((): boolean => {
    if (user.email === user.license.email_admin_user || user.empresa.admin || !user.empresa.viewer) {
      return true;
    }

    return false;
  }, [user]);

  const defineDashboardFinancialAccounts = useCallback((): void => {
    if (isUserAdmin()) {
      if (user.empresa.user_powerbi.url_powerbi_financial_accounts) {
        setUrlPowerBI(user.empresa.user_powerbi.url_powerbi_financial_accounts);
        return;
      }

      if (user.empresa.url_powerbi_financial_accounts) {
        setUrlPowerBI(user.empresa.url_powerbi_financial_accounts);
        return;
      }

      setUrlPowerBI(user.license.url_powerbi_financial_accounts);
      return;
    }

    setUrlPowerBI(user.empresa.user_powerbi.url_powerbi_financial_accounts);
  }, [user, isUserAdmin]);

  const defineDashboardAccountingAnalysis = useCallback((): void => {
    if (isUserAdmin()) {
      if (user.empresa.user_powerbi.url_powerbi_accouting_analysis) {
        setUrlPowerBI(user.empresa.user_powerbi.url_powerbi_accouting_analysis);
        return;
      }

      if (user.empresa.url_powerbi) {
        setUrlPowerBI(user.empresa.url_powerbi);
        return;
      }

      setUrlPowerBI(user.license.url_powerbi);
      return;
    }

    setUrlPowerBI(user.empresa.user_powerbi.url_powerbi_accouting_analysis);
  }, [user, isUserAdmin]);

  const defineDashboardCostAnalysis = useCallback((): void => {
    if (isUserAdmin()) {
      if (user.empresa.user_powerbi.url_powerbi_cost_analysis) {
        setUrlPowerBI(user.empresa.user_powerbi.url_powerbi_cost_analysis);
        return;
      }

      if (user.empresa.url_powerbi_cost_analysis) {
        setUrlPowerBI(user.empresa.url_powerbi_cost_analysis);
        return;
      }

      setUrlPowerBI(user.license.url_powerbi_cost_analysis);
      return;
    }

    setUrlPowerBI(user.empresa.user_powerbi.url_powerbi_cost_analysis);
  }, [user, isUserAdmin]);

  const defineDashboardHumanResources = useCallback((): void => {
    if (isUserAdmin()) {
      if (user.empresa.user_powerbi.url_powerbi_human_resources) {
        setUrlPowerBI(user.empresa.user_powerbi.url_powerbi_human_resources);
        return;
      }

      if (user.empresa.url_powerbi_human_resources) {
        setUrlPowerBI(user.empresa.url_powerbi_human_resources);
        return;
      }

      setUrlPowerBI(user.license.url_powerbi_human_resources);
      return;
    }

    setUrlPowerBI(user.empresa.user_powerbi.url_powerbi_human_resources);
  }, [user, isUserAdmin]);

  useEffect(() => {
    if (user && user.license && user.empresa) {
      if (licencaInvalida()) return;

      if (!user.empresa.user_powerbi) {
        setUrlPowerBI('');
        return;
      }

      switch (user.empresa.active_system_kind) {
        case ActiveSystemKindOptions.Option_FinancialAccounts: {
          defineDashboardFinancialAccounts();
          break;
        }
        case ActiveSystemKindOptions.Option_AccountingAnalysis: {
          defineDashboardAccountingAnalysis();
          break;
        }
        case ActiveSystemKindOptions.Option_CostAnalysis: {
          defineDashboardCostAnalysis();
          break;
        }
        case ActiveSystemKindOptions.Option_HumanResources: {
          defineDashboardHumanResources();
          break;
        }
        default: {
          defineDashboardAccountingAnalysis();
        }
      }
    }
  }, [
    licencaInvalida,
    user,
    defineDashboardAccountingAnalysis,
    defineDashboardFinancialAccounts,
    defineDashboardCostAnalysis,
    defineDashboardHumanResources,
  ]);

  return (
    <Container>
      <Header />

      <Content>{urlPowerBI !== '' && <IFramePowerBI title="Analise" src={urlPowerBI} ehDashboard />}</Content>
    </Container>
  );
};

export default DashboardPowerBI;
