import React, { useState, useCallback, ReactElement } from 'react';
import { Box, IconButton } from '@material-ui/core';
import {
  DataGrid,
  GridColDef,
  GridCellParams,
  ptBR,
  GridFooterContainer,
  GridPagination,
} from '@material-ui/data-grid';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { AiOutlineCaretDown, AiOutlineCaretUp, AiFillWarning } from 'react-icons/ai';
import './style.css';

import {
  ColumnId,
  ColumnDescricao,
  ColumnSituacao,
  ColumnTipo,
  ColumnValorReceber,
  ColumnValorPagar,
  ColumnContaCorrente,
  ColumnBanco,
  ColumnConta,
  ColumnCentroCusto,
} from './TableCashFlowGridColumns';
import { getPathGrid } from '../../../utils/storagePath';

export interface IDailyCashFlow {
  id: string;
  id_conta_corrente: string;
  id_conta_corrente_transferencia: string;
  tipo: string;
  valor_original: number;
  valor_quitacao: number;
  situacao: string;
  data: Date;
  data_vencimento: Date;
  data_quitacao: Date;
  descricao_movimento: string;
}

interface IDailyCashFlowProps {
  listDailyCashFlow: IDailyCashFlow[];
  totalValorOriginal: string;
  totalValorQuitacao: string;
}

const theme = createMuiTheme(
  {
    palette: {
      primary: { main: '#006699' },
    },
  },
  ptBR,
);

const TableCashFlow: React.FC<IDailyCashFlowProps> = ({
  listDailyCashFlow,
  totalValorOriginal,
  totalValorQuitacao,
}) => {
  const [gridRowHeight, setGridRowHeight] = useState<number>(46);
  function exibrAlerta(inDate: string, inSituacao: string): boolean {
    const [dia, mes, ano] = inDate.split('/').map(Number);
    const dataParametro = new Date(ano, mes - 1, dia);
    const hoje = new Date();
    hoje.setHours(0, 0, 0, 0);
    const result = inSituacao !== 'Quitado' && hoje > dataParametro;
    return result;
  }

  const ColumnData: GridColDef = {
    headerName: 'Data',
    field: 'data',
    width: 150,
    type: 'date',
    renderCell: (params: GridCellParams) => {
      if (params.value === null) return <strong />;
      const ano = (params.value as string).substring(0, 4);
      const mes = (params.value as string).substring(5, 7);
      const dia = (params.value as string).substring(8, 10);

      const dataFormatada = `${dia}/${mes}/${ano}`;
      return <strong>{dataFormatada}</strong>;
    },
  };

  const ColumnDataVencimento: GridColDef = {
    headerName: 'Data vencimento',
    field: 'data_vencimento',
    width: 190,
    type: 'date',
    renderCell: (params: GridCellParams) => {
      if (params.value === null) return <strong />;
      const ano = (params.value as string).substring(0, 4);
      const mes = (params.value as string).substring(5, 7);
      const dia = (params.value as string).substring(8, 10);
      const dataFormatada = `${dia}/${mes}/${ano}`;
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <span>{dataFormatada}</span>
          {exibrAlerta(dataFormatada, params.row.situacao) ? (
            <AiFillWarning style={{ color: '#FFCC00', marginLeft: 10 }} size={22} />
          ) : (
            <></>
          )}
        </div>
      );
    },
  };

  const columns: GridColDef[] = [
    ColumnTipo,
    ColumnSituacao,
    ColumnData,
    ColumnDataVencimento,
    ColumnValorReceber,
    ColumnValorPagar,
    ColumnDescricao,
    ColumnContaCorrente,
    ColumnBanco,
    ColumnConta,
    ColumnCentroCusto,
    ColumnId,
  ];

  const handleGridRowHeightUp = useCallback(async () => {
    const heightValue: number = gridRowHeight + 1;
    setGridRowHeight(heightValue);
    localStorage.setItem(getPathGrid(), heightValue.toString());
  }, [gridRowHeight]);

  const handleGridRowHeightDown = useCallback(async () => {
    const heightValue: number = gridRowHeight - 1;
    setGridRowHeight(heightValue);
    localStorage.setItem(getPathGrid(), heightValue.toString());
  }, [gridRowHeight]);

  const DataGridTitle = (): ReactElement => {
    return (
      <Box
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div className="astTableCashFlowHeader">
          <div className="astTableCashFlowHeaderButtons">
            <IconButton id="gridrowheightdown" color="primary" onClick={() => handleGridRowHeightDown()}>
              <AiOutlineCaretDown />
            </IconButton>
            <IconButton color="primary" id="gridrowheightup" onClick={() => handleGridRowHeightUp()}>
              <AiOutlineCaretUp />
            </IconButton>
          </div>

          <div>
            <h2 style={{ color: '#006699' }}>Fluxo de caixa diário</h2>
          </div>
        </div>
      </Box>
    );
  };

  const DataGridFooter = (): ReactElement => {
    return (
      <GridFooterContainer>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: 1,
            fontSize: '14px',
          }}
        >
          <div
            style={{
              marginLeft: '6px',
              marginRight: '20px',
              color: '#007FFF',
              border: '1px solid #007FFF',
              borderRadius: 4,
              padding: 4,
            }}
          >
            <span>Valor original receber:</span>
            <strong style={{ fontWeight: 'bold', marginLeft: 6 }}>{totalValorOriginal}</strong>
          </div>
          <div
            style={{
              marginLeft: '6px',
              color: 'red',
              border: '1px solid red',
              borderRadius: 4,
              padding: 4,
            }}
          >
            <span>Valor original pagar:</span>
            <strong style={{ fontWeight: 'bold', marginLeft: 6 }}>{totalValorQuitacao}</strong>
          </div>
        </Box>

        <GridPagination />
      </GridFooterContainer>
    );
  };

  return (
    <div className="astTableCashFlow">
      <ThemeProvider theme={theme}>
        <DataGrid
          rows={listDailyCashFlow}
          columns={columns}
          rowHeight={gridRowHeight}
          components={{
            Toolbar: DataGridTitle,
            Footer: DataGridFooter,
          }}
        />
      </ThemeProvider>
    </div>
  );
};

export default TableCashFlow;
